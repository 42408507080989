import React from 'react';
import {
  Box, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Calendar from '../files/icons/calendar.svg';
import Townhall from '../files/icons/townhall.svg';
import Brunch from '../files/icons/brunch.svg';
import Toast from '../files/icons/toast.svg';

function WeddingDetails() {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const weddingDate = new Date('2025-07-12T13:00:00Z');
  const formattedDate = weddingDate.toLocaleDateString('fr-FR', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'Europe/Paris',
  });
  const formattedTime = weddingDate.toLocaleTimeString('fr-FR', {
    hour: 'numeric',
    minute: 'numeric',
    timeZone: 'Europe/Paris',
  });

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      mt: 12,
      mb: 3,
      position: 'relative',
      px: 6,
      py: 20,
      backgroundColor: 'background.default',
    }}
    >

      {/* <Typography
        sx={{
          mb: 6,
          typography: {
            xs: 'h3',
            md: 'h2',
          },
        }}
      >
        LE MARIAGE
      </Typography> */}
      {isLargeScreen && (
        <Box sx={{
          position: 'absolute',
          left: '50%',
          top: 220,
          width: '3px',
          height: 475,
          backgroundColor: 'primary.main',
        }}
        />
      )}
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: 6,
      }}
      >
        <Box sx={{
          display: 'flex', flexDirection: 'column', gap: 3, alignItems: 'center', flexGrow: 1,
        }}
        >
          <Typography variant="h5" sx={{ color: 'terracota.main' }}>QUAND</Typography>

          <Box sx={{
            display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 3,
          }}
          >
            <img src={Calendar} alt="calendar" style={{ width: 35, height: 35 }} />
            <Typography variant="body1">
              Le
              {' '}
              {formattedDate}
              {'\n'}
              à
              {' '}
              {formattedTime}
            </Typography>
          </Box>

        </Box>
        <Box sx={{
          display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center', flexGrow: 1,
        }}
        >
          <Typography variant="h5" sx={{ color: 'terracota.main' }}>OÙ</Typography>
          <Box sx={{
            display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 3,
          }}
          >
            <Box sx={{
              display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'center',
            }}
            >
              <Typography variant="h6">
                Mariage civil
              </Typography>
              <Typography variant="h6">
                {'Mairie de Gouesnac\'h'}
              </Typography>
              <Typography variant="body2">
                {'19 Route de Bénodet\n29950 Gouesnach'}
              </Typography>
            </Box>
            <img src={Townhall} alt="calendar" style={{ width: 50, height: 50 }} />
          </Box>
          <ArrowDownwardIcon sx={{ my: 2 }} />
          <Box sx={{
            display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 3,
          }}
          >
            <Box sx={{
              display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'center',
            }}
            >
              <Typography variant="h6">
                Réception
              </Typography>
              <Typography variant="h6">
                Manoir Hôtel des Indes
              </Typography>
              <Typography variant="body2">
                {'1, allée de Prad ar C\'Hras\n29000 Quimper'}
              </Typography>
            </Box>
            <img src={Toast} alt="calendar" style={{ width: 50, height: 50 }} />
          </Box>
          <ArrowDownwardIcon sx={{ my: 2 }} />
          <Box sx={{
            display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 3,
          }}
          >
            <Box sx={{
              display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'center',
            }}
            >
              <Typography variant="h6">
                Brunch du lendemain
              </Typography>
              <Typography variant="h6">
                Les Vergers de Kermao
              </Typography>
              <Typography variant="body2">
                {'12 Hent Kermao\n29950 Gouesnach'}
              </Typography>
            </Box>
            <img src={Brunch} alt="calendar" style={{ width: 50, height: 50 }} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default WeddingDetails;
