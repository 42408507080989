/* eslint-disable camelcase */
import React, { useState, useCallback, useEffect } from 'react';
import {
  Box, Typography, TextField, List, ListItem, ListItemText, Paper, Button,
} from '@mui/material';
// import SpotifyWebApi from 'spotify-web-api-js';
import LoadingButton from '@mui/lab/LoadingButton';
// import { httpsCallable } from 'firebase/functions';
import { useFirestore, useFunctions } from 'reactfire';
import { collection, addDoc } from 'firebase/firestore';
import LaunchIcon from '@mui/icons-material/Launch';
import SendIcon from '@mui/icons-material/Send';
import useGlobal from '../../global-state/store';

// const spotifyApi = new SpotifyWebApi();

function SpotifyPlaylist() {
  const [query, setQuery] = useState('');
  const [tracks, setTracks] = useState([]);
  //   const functions = useFunctions();
  //   const [accessToken, setAccessToken] = useState(localStorage.getItem('pipoSptAccessToken') || '');
  //   const [refreshToken, setRefreshToken] = useState(localStorage.getItem('pipoSptRefreshToken') || '');
  //   const [expiresThe, setExpiresThe] = useState(new Date(localStorage.getItem('pipoSptExpiresThe')) || new Date(0));
  const [loading, setLoading] = useState(false);
  //   const getSpotifyAccessToken = httpsCallable(functions, 'getSpotifyAccessToken');
  //   const [shouldTryLogin, setShouldTryLogin] = useState(true);
  //   const [shouldTryResfresh, setShouldTryRefresh] = useState(true);
  const [suggestedTrack, setSuggestedTrack] = useState('');

  const [, globalActions] = useGlobal();
  const firestore = useFirestore();

  //   const setTokens = useCallback((tokens) => {
  //     const { newAccessToken, newExpiresIn, newRefreshToken } = tokens;

  //     setAccessToken(newAccessToken);
  //     const expiresInMs = newExpiresIn * 1000;
  //     const expirationDate = new Date(Date.now() + expiresInMs);
  //     setExpiresThe(expirationDate.toISOString());
  //     setRefreshToken(newRefreshToken);
  //     localStorage.setItem('pipoSptAccessToken', newAccessToken);
  //     localStorage.setItem('pipoSptExpiresThe', expirationDate.toISOString());
  //     localStorage.setItem('pipoSptRefreshToken', newRefreshToken);
  //     spotifyApi.setAccessToken(newAccessToken);
  //   }, []);

  //   const getRefreshToken = useCallback(async () => {
  //     console.log('accessToken: ', accessToken);
  //     console.log('refreshToken: ', refreshToken);
  //     console.log('expiresThe: ', expiresThe);
  //     const url = 'https://accounts.spotify.com/api/token';

  //     const payload = {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/x-www-form-urlencoded',
  //       },
  //       body: new URLSearchParams({
  //         grant_type: 'refresh_token',
  //         refresh_token: refreshToken,
  //         client_id: clientId,
  //       }),
  //     };
  //     const body = await fetch(url, payload);
  //     const response = await body.json();
  //     return response;
  //   }, [refreshToken]);

  //   const handleTokenRefresh = useCallback(async () => {
  //     try {
  //       setLoading(true);
  //       const data = await getRefreshToken();
  //       console.log('DATA: ', data);
  //       const { access_token: newAccessToken, expires_in: newExpiresIn } = data.body;
  //     //   setTokens({ newAccessToken, newExpiresIn, newRefreshToken: refreshToken });
  //     } catch (error) {
  //       console.log('HERE: ', error);
  //       globalActions.setSnackbarMessage({
  //         message: 'Petit problème, réessaye plus tard',
  //         severity: 'error',
  //         duration: 3000,
  //       });
  //     } finally {
  //       setLoading(false);
  //       setShouldTryRefresh(false);
  //     }
  //   }, [getRefreshToken, globalActions]);

  //   useEffect(() => {
  //     const fetchAccessToken = async () => {
  //       try {
  //         setLoading(true);
  //         const result = await getSpotifyAccessToken();
  //         const {
  //           accessToken: newAccessToken,
  //           expiresIn: newExpiresIn, refreshToken: newRefreshToken,
  //         } = result.data;
  //         setTokens({ newAccessToken, newExpiresIn, newRefreshToken });
  //       } catch (error) {
  //         globalActions.setSnackbarMessage({
  //           message: 'Petit problème, réessaye plus tard',
  //           severity: 'error',
  //           duration: 3000,
  //         });
  //       } finally {
  //         setLoading(false);
  //         setShouldTryLogin(false);
  //       }
  //     };

  //     if (!accessToken && loading === false && shouldTryLogin) {
  //     //   console.log('FETCH');
  //       fetchAccessToken();
  //     } else if (new Date(expiresThe) <= new Date() && loading === false && shouldTryResfresh) {
  //     //   console.log('REFRESH');
  //       handleTokenRefresh();
  //     } else if (accessToken) {
  //     //   console.log('SET');
  //       spotifyApi.setAccessToken(accessToken);
  //     }
  //   }, [refreshToken, getSpotifyAccessToken, globalActions, accessToken, expiresThe,
  //     setTokens, handleTokenRefresh, loading, shouldTryLogin, shouldTryResfresh]);

  const successMessages = [
    'Merci !',
    'C\'est comme si c\'était fait!',
    'Merci bro\' / sis\' !',
    'Gracias, amigo o amiga !',
    'Danke, mein Freund!',
    'Grazie mille!',
    'ありがとう、仲間！',
    'Obrigado, parceiro!',
    'Merci 1000 fois ! Non ! 2000 fois même !',
  ];

  const getRandomSuccessMessage = () => {
    const randomIndex = Math.floor(Math.random() * successMessages.length);
    return successMessages[randomIndex];
  };

  //   const handleSearch = async () => {
  //     try {
  //       setLoading(true);
  //       const data = await spotifyApi.searchTracks(query, { limit: 20 });
  //       setTracks(data.tracks.items);
  //     } catch (error) {
  //       globalActions.setSnackbarMessage({
  //         message: 'Petit problème, réessaye',
  //         severity: 'error',
  //         duration: 3000,
  //       });
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  const handleAddTrack = async (track) => {
    try {
      setLoading(true);
      await addDoc(collection(firestore, 'spotifyPlaylist'), {
        trackId: track.id,
        trackName: track.name,
        artistNames: track.artists.map((artist) => artist.name),
        addedAt: new Date(),
      });
      globalActions.setSnackbarMessage({
        message: getRandomSuccessMessage(),
        severity: 'success',
        duration: 3000,
      });
    } catch (error) {
      globalActions.setSnackbarMessage({
        message: 'Petit problème, réessaye plus tard',
        severity: 'error',
        duration: 3000,
      });
    } finally {
      setLoading(false);
    }

    // const playlistId = '3AVunTQdwIazCIdo05RbRF';
    // try {
    //   setLoading(true);
    //   await spotifyApi.addTracksToPlaylist(playlistId, [track.uri]);
    //   globalActions.setSnackbarMessage({
    //     message: 'Musique ajoutée à la playlist !',
    //     severity: 'success',
    //     duration: 3000,
    //   });
    // } catch (error) {
    //   globalActions.setSnackbarMessage({
    //     message: 'Petit problème, réessaye',
    //     severity: 'error',
    //     duration: 3000,
    //   });
    // } finally {
    //   setLoading(false);
    // }
  };

  const handleSuggestTrack = () => {
    if (suggestedTrack.trim() !== '') {
      const track = {
        trackId: `suggested-${Date.now()}`,
        trackName: suggestedTrack,
        artistNames: [],
        addedAt: new Date(),
      };
      handleAddTrack(track);
      setSuggestedTrack('');
    }
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 3,
      mt: 15,
      mx: 1,
      mb: 12,
    }}
    >
      <Box sx={{ backgroundColor: 'rgba(255, 255, 255, 0.7)', p: 1 }}>
        <Typography sx={{
          color: 'deepPrimary.main',
          typography: {
            xs: 'h5',
            md: 'h4',
          },
        }}
        >
          {'Ajoute les sons qui t\'ambiancent !'}
        </Typography>
      </Box>
      <Box sx={{ width: '100%', maxWidth: '400px' }}>
        <iframe
          title="playlist"
          style={{ borderRadius: 12 }}
          src="https://open.spotify.com/embed/playlist/3AVunTQdwIazCIdo05RbRF?utm_source=generator"
          width="100%"
          height="352"
          frameBorder="0"
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          loading="lazy"
        />
      </Box>
      <Button
        size="large"
        variant="contained"
        color="primary"
        // eslint-disable-next-line max-len
        href="https://open.spotify.com/playlist/3AVunTQdwIazCIdo05RbRF?si=c0165ec926d544ac&pt=b59a707acd4ec47d287c0dada3274d03"
        target="_blank"
        sx={{ mt: 2 }}
        endIcon={<LaunchIcon />}
      >
        Ajoute directement sur Spotify
      </Button>
      <Typography sx={{ mt: 3 }}>
        Ou suggère une musique que nous ajouterons pour toi :
      </Typography>
      <Box sx={{
        display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1,
      }}
      >
        <TextField
          label="Nom, Artiste"
          variant="outlined"
          value={suggestedTrack}
          onChange={(e) => setSuggestedTrack(e.target.value)}
        />
        <Button
          variant="outlined"
          color="primary"
          onClick={handleSuggestTrack}
          endIcon={<SendIcon />}
        >
          Envoyer
        </Button>
      </Box>
      {/* <Paper
        elevation={0}
        sx={{
          maxHeight: 800,
          mt: 12,
          overflow: 'auto',
          width: '100%',
          maxWidth: 800,
          display: 'flex',
          flexDirection: 'column',
          p: 3,
          alignItems: 'center',
          gap: 3,
          mb: 6,
        }}
      >
        <TextField
          label="Rechercher un son"
          variant="outlined"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <LoadingButton
          loading={loading}
          variant="outlined"
          color="primary"
          onClick={handleSearch}
        >
          Chercher
        </LoadingButton>

        <List>
          {tracks.map((track) => (
            <ListItem key={track.id} button onClick={() => handleAddTrack(track)}>
              <ListItemText primary={track.name} secondary={track.artists.map((artist) => artist.name).join(', ')} />
            </ListItem>
          ))}
        </List>
      </Paper> */}
    </Box>

  );
}

export default SpotifyPlaylist;
